<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 18.919 16.997"
    aria-hidden="true"
  >
    <g stroke="currentColor">
      <g fill="currentColor" stroke-width=".4">
        <path
          class="transition-transform group-hover:translate-x-[0.125rem] group-hover:translate-y-[-0.125rem]"
          d="M14.542 9.312a.607.607 0 0 1-.139-.49l.494-4.705a.647.647 0 0 1 .728-.59.647.647 0 0 1 .59.728L15.72 8.96a.647.647 0 0 1-.728.59.607.607 0 0 1-.45-.239Z"
        />
        <path
          class="transition-transform group-hover:translate-x-[0.125rem] group-hover:translate-y-[-0.125rem]"
          d="M10.385 3.747a.607.607 0 0 1-.139-.49.647.647 0 0 1 .728-.588l4.705.493a.647.647 0 0 1 .589.727.647.647 0 0 1-.728.59l-4.705-.494a.607.607 0 0 1-.45-.238Z"
        />
        <path
          class="transition-transform group-hover:translate-x-[0.125rem] group-hover:translate-y-[-0.125rem]"
          d="M6.689 11.265a.616.616 0 0 1 .071-.918l7.882-6.383a.616.616 0 0 1 .914.12.616.616 0 0 1-.072.92l-7.881 6.382a.616.616 0 0 1-.914-.12Z"
        />
      </g>
      <path
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.5"
        d="M12.013 13.763v2.484H.75V5.737h3.369"
      />
    </g>
  </svg>
</template>
